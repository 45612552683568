import React, { InputHTMLAttributes } from "react"
import { FieldErrors } from "react-hook-form"
import "twin.macro"
import tw, { css } from "twin.macro"

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  id: string
  name: string
  type: string
  error?: FieldErrors
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(({ id, name, type, error, children, ...rest }, ref) => {
  return (
    <input
      id={id}
      name={name}
      type={type}
      ref={ref}
      {...rest}
      tw="px-4 py-2 rounded-md border border-primary focus:(ring ring-primary-light outline-none)"
      css={[error && tw`border-red-500 focus:(ring-red-300)`]}
    />
  )
})

export default Input
