import "twin.macro"

interface InputGroupProps {
  id: string
  label: string
}

const InputGroup: React.FC<InputGroupProps> = ({ id, label, children }) => {
  return (
    <div tw="flex flex-col sibling:mt-4">
      <label htmlFor={id} tw="mb-1 font-display">
        {label}
      </label>
      {children}
    </div>
  )
}

export default InputGroup
