import { superstructResolver } from "@hookform/resolvers/superstruct"
import { graphql, navigate, PageProps } from "gatsby"
import isEmail from "is-email"
import * as React from "react"
import { useForm } from "react-hook-form"
import { define, nonempty, object, string } from "superstruct"
import "twin.macro"
import tw from "twin.macro"
import { css } from "@emotion/core"
import Container from "../components/Container"
import Input from "../components/Input"
import InputGroup from "../components/InputGroup"
import Markdown from "../components/Markdown"
import Seo from "../components/seo"
import Button from "../components/Button"

interface ContactProps extends PageProps {
  data: {
    file?: {
      childMarkdownRemark: {
        frontmatter: {
          title: string
          intro: string
        }
      }
    }
  }
}

const Index = ({ data }: ContactProps) => {
  const { intro } = data.file?.childMarkdownRemark.frontmatter ?? {
    title: "",
    intro: "",
  }

  const Email = define("Email", isEmail)

  const schema = object({
    name: nonempty(string()),
    email: Email,
    message: nonempty(string()),
  })

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    resolver: superstructResolver(schema),
  })

  const onSubmit = async (data: Record<string, string>) => {
    await fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: new URLSearchParams(data).toString(),
    })

    navigate("/contact-submitted/")
  }

  return (
    <>
      <Seo title="Contact" />
      <Container>
        <div tw="flex flex-col items-center">
          <div tw="prose text-center">
            <Markdown content={intro} />
          </div>
          <div tw="w-full lg:w-1/2 xl:w-1/4">
            <form
              name="contact"
              method="POST"
              data-netlify="true"
              onSubmit={handleSubmit(onSubmit)}
            >
              <input type="hidden" name="form-name" value="contact" />
              <InputGroup id="name" label="Name">
                <Input
                  id="name"
                  type="text"
                  error={errors.name}
                  {...register("name")}
                  autoFocus
                />
              </InputGroup>
              <InputGroup id="email" label="Email">
                <Input
                  id="email"
                  type="email"
                  error={errors.email}
                  {...register("email")}
                />
              </InputGroup>
              <InputGroup id="message" label="Message">
                <textarea
                  id="message"
                  tw="px-4 py-2 rounded-md border border-primary focus:(ring ring-primary-light outline-none)"
                  css={[
                    errors.message && tw`border-red-500 focus:(ring-red-300)`,
                  ]}
                  rows={5}
                  {...register("message")}
                />
              </InputGroup>
              <div tw="flex justify-end">
                <Button type="submit">Submit</Button>
              </div>
            </form>
          </div>
        </div>
      </Container>
    </>
  )
}

export default Index

export const pageQuery = graphql`
  query {
    file(sourceInstanceName: { eq: "pages" }, name: { eq: "contact" }) {
      childMarkdownRemark {
        frontmatter {
          intro
          title
        }
      }
    }
  }
`
